<mat-toolbar class="navbar" fxShow fxHide.lt-md>
  <div class="item">
    <div [routerLink]="['/']">
      <span class="navbar-title">Turnverein</span>
      <span class="navbar-subtitle">1903 Merxheim e.V.</span>
    </div>
    <img class="logo" src="../assets/img/tv_merxheim_logo.png">
  </div>
  <div>
    <p [routerLink]="['/news']" routerLinkActive="navbar-link-active"  class="navbar-link">NEWS</p>
    <p [routerLink]="['/events']" routerLinkActive="navbar-link-active" class="navbar-link">TERMINE</p>
    <p [routerLink]="['/activities']" routerLinkActive="navbar-link-active"  class="navbar-link">AKTIVITÄTEN</p>
    <p [routerLink]="['/committee']" routerLinkActive="navbar-link-active"  class="navbar-link">VORSTAND</p>
    <p [routerLink]="['/contact']" routerLinkActive="navbar-link-active"  class="navbar-link">KONTAKT</p>
  </div>
  <span class="navbar-spacer">
    <button [routerLink]="['/member']" mat-flat-button>MITGLIED WERDEN</button>
  </span>
</mat-toolbar>
<div class="container" fxHide fxShow.lt-md>
  <mat-toolbar class="navbar">
    <img class="logo" src="../assets/img/tv_merxheim_logo.png" [routerLink]="['/']" (click)="snav.close()">
    <button mat-icon-button *ngIf="!snav.opened" (click)="snav.toggle()"><mat-icon>menu</mat-icon></button>
    <button mat-icon-button *ngIf="snav.opened" (click)="snav.toggle()"><mat-icon>close</mat-icon></button>
  </mat-toolbar>
  <mat-sidenav-container class="sidenav-container">
    <mat-sidenav #snav [opened]="false" mode="over" class="sidenav-container" [fixedInViewport]="mobileQuery.matches">
      <mat-nav-list>
        <a (click)="snav.close()" [routerLink]="['/news']" routerLinkActive="navbar-link-active"  class="navbar-link">NEWS</a>
        <a (click)="snav.close()" [routerLink]="['/events']" routerLinkActive="navbar-link-active" class="navbar-link">TERMINE</a>
        <a (click)="snav.close()" [routerLink]="['/activities']" routerLinkActive="navbar-link-active"  class="navbar-link">AKTIVITÄTEN</a>
        <a (click)="snav.close()" [routerLink]="['/committee']" routerLinkActive="navbar-link-active"  class="navbar-link">VORSTAND</a>
        <a (click)="snav.close()" [routerLink]="['/contact']" routerLinkActive="navbar-link-active"  class="navbar-link">KONTAKT</a>
        <button (click)="snav.close()" [routerLink]="['/member']" mat-flat-button>MITGLIED WERDEN</button>
      </mat-nav-list>
    </mat-sidenav>
    <mat-sidenav-content>
      <router-outlet></router-outlet>
    </mat-sidenav-content>
  </mat-sidenav-container>
</div>
<router-outlet></router-outlet>
<footer>
  <div class="main-footer">
    <section class="company">
      <a href="https://www.tvm.org/" target="_blank"><img class="external-logo" src="../assets/img/turnverband_mittelrhein.png"></a>
      <a href="https://www.sportbund-rheinland.de/" target="_blank"><img class="external-logo" src="../assets/img/sportbund_rheinland.svg"></a>
    </section>
    <section>
      <h3>Kontakt</h3>
      <a href="tel:067548553"><mat-icon>phone</mat-icon> 06754 8553</a>
      <a href="tel:015111620967"><mat-icon>phone</mat-icon> 0151 11620967</a>
      <a href="mailto:kontakt@turnverein-merxheim.de"><mat-icon>mail</mat-icon>E-Mail schreiben</a>
    </section>
    <section>
      <h3>Links</h3>
      <p [routerLink]="['/about']" class="footer-link">Impressum</p>
      <p [routerLink]="['/privacy']" class="footer-link">Datenschutz</p>
    </section>
    <section class="company">
      <div>
        <span class="name">Turnverein</span>
        <span class="sub-name">1903 Merxheim e.V.</span>
      </div>
      <img class="company-logo" src="../assets/img/tv_merxheim_logo.png">
    </section>
  </div>
  <div class="sub-footer" >
    <div>&copy; 2023 Turnverein 1903 Merxheim e.V.</div>
  </div>
</footer>