import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ContactService {

  constructor(private httpClient:HttpClient) { }

  private api:string = 'https://mailthis.to/kontakttvmerxheim';

  postMessage(jsonString:string) {
    return this.httpClient.post(this.api, jsonString, {
      headers: { 
        'Content-Type': 'application/json',
        'Accept': 'application/json'
      },
      responseType: 'text'
    }).pipe(map((response:any) => {return response;}))
  }
}
