<div class="content">
    <div>
        <p>DEIN KONTAKT ZUM</p>
        <p class="title">Turnverein Merxheim</p>
        <p class="subtitle">Deine Nachricht an uns.</p>
        <p class="text">
            Du hast Fragen zu unseren Aktivitäten oder benötigst Hilfe rund um das Thema Mitgliedschaft? <br>
            Dann schreib uns gerne eine Nachricht und wir setzen uns mit Dir in Verbindung. <br>
            Deine Daten werden nicht an Dritte weitergegeben.
        </p>
    </div>
    <div>
        <form [formGroup]="formGroup" (ngSubmit)="onSubmit()">
            <mat-form-field>
                <mat-label>Dein Name</mat-label>
                <input matInput type="text" name="name" placeholder="Dein Name" formControlName="name">
            </mat-form-field>
            <mat-form-field>
                <mat-label>Deine E-Mail</mat-label>
                <input matInput type="email" name="email" placeholder="Deine E-Mail" formControlName="email">
            </mat-form-field>
            <mat-form-field>
                <mat-label>Nachricht</mat-label>
                <textarea matInput class="form-control" formControlName="message" name="message"></textarea>
            </mat-form-field>
            <mat-checkbox formControlName="acceptPrivacy">Ja, ich habe die <a href="/privacy" target="_blank">Datenschutzerklärung</a> zur Kenntnis genommen.</mat-checkbox>
            <button mat-flat-button type="submit" [disabled]="!formGroup.valid">Absenden</button>
        </form>
    </div>
</div>