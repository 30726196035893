import { Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Activity } from '../activities/activities';
import { ActivityDetailsComponent } from '../activity-details/activity-details.component';

@Component({
  selector: 'app-activity-builder',
  templateUrl: './activity-builder.component.html',
  styleUrls: ['./activity-builder.component.scss']
})
export class ActivityBuilderComponent implements OnInit {

  @Input() activities: Activity[] = [];

  constructor(public dialog: MatDialog) {}

  ngOnInit(): void {}

  openDialog(activity:Activity) {
    const dialogRef = this.dialog.open(ActivityDetailsComponent,{ data: activity });
  }

}
