<div class="content">
    <section>
        <h2>UNSER VORSTAND</h2>
        <div>
            <mat-card class="committee-card">
                <img mat-card-image src="../assets/img/Fethi Bayer.jpg">
                <mat-card-content>
                    <p class="name">Fethi Bayer</p>
                    <p class="title">1. Vorsitzender</p>
                </mat-card-content>
            </mat-card>
            <mat-card class="committee-card">
                <img mat-card-image src="../assets/img/Nicola Rosenow.jpeg">
                <mat-card-content>
                    <p class="name">Nicola Rosenow</p>
                    <p class="title">2. Vorsitzende</p>
                </mat-card-content>
            </mat-card>
            <mat-card class="committee-card">
                <img mat-card-image src="../assets/img/Silka Betke-Bock.jpg">
                <mat-card-content>
                    <p class="name">Silke Betke-Bock</p>
                    <p class="title">Geschäftsführerin</p>
                </mat-card-content>
            </mat-card>
            <mat-card class="committee-card">
                <img mat-card-image src="../assets/img/Inka Rosenow.jpeg">
                <mat-card-content>
                    <p class="name">Inka Rosenow</p>
                    <p class="title">Stellv. Geschäftsführerin</p>
                </mat-card-content>
            </mat-card>
            <mat-card class="committee-card">
                <img mat-card-image src="../assets/img/Christine Theile.jpeg">
                <mat-card-content>
                    <p class="name">Christine Theile</p>
                    <p class="title">Resortleiterin Finanzen</p>
                </mat-card-content>
            </mat-card>
            <mat-card class="committee-card">
                <img mat-card-image src="../assets/img/Hans Peter Theile.jpeg">
                <mat-card-content>
                    <p class="name">Hans Peter Theile</p>
                    <p class="title">Stellv. Resortleiter Finanzen</p>
                </mat-card-content>
            </mat-card>
            <mat-card class="committee-card">
                <img mat-card-image src="../assets/img/Alina Rosenow Beisitzerin Vertr. Jugend.jpg">
                <mat-card-content>
                    <p class="name">Alina Rosenow</p>
                    <p class="title">Vertreterin Jugend</p>
                </mat-card-content>
            </mat-card>
            <mat-card class="committee-card">
                <img mat-card-image src="../assets/img/Anne Hollaender.jpeg">
                <mat-card-content>
                    <p class="name">Anne Holländer</p>
                    <p class="title">Beisitzerin</p>
                </mat-card-content>
            </mat-card>
            <mat-card class="committee-card">
                <img mat-card-image src="../assets/img/Karola Fries.jpg">
                <mat-card-content>
                    <p class="name">Karola Fries</p>
                    <p class="title">Beisitzerin</p>
                </mat-card-content>
            </mat-card>
            <mat-card class="committee-card">
                <img mat-card-image src="../assets/img/Eckard Theile.jpg">
                <mat-card-content>
                    <p class="name">Eckhard Theile</p>
                    <p class="title">Beisitzer</p>
                </mat-card-content>
            </mat-card>
            <mat-card class="committee-card">
                <img mat-card-image src="../assets/img/Markus Schubert.jpg">
                <mat-card-content>
                    <p class="name">Markus Schubert</p>
                    <p class="title">Geräte- und Wanderwart</p>
                </mat-card-content>
            </mat-card>
            <mat-card class="committee-card">
                <img mat-card-image src="../assets/img/guenther_fries.jpg">
                <mat-card-content>
                    <p class="name">Günther Fries</p>
                    <p class="title">Gerätewart</p>
                </mat-card-content>
            </mat-card>
            <mat-card class="committee-card">
                <img mat-card-image src="../assets/img/Miltiadis Tsairidis.jpg">
                <mat-card-content>
                    <p class="name">Miltiadis Tsairidis</p>
                    <p class="title">Eventmanager</p>
                </mat-card-content>
            </mat-card>
            <mat-card class="committee-card">
                <img mat-card-image src="../assets/img/Irene Zerfass.jpg">
                <mat-card-content>
                    <p class="name">Irene Zerfass</p>
                    <p class="title">Turnwartin</p>
                </mat-card-content>
            </mat-card>
        </div>
    </section>
</div>