import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-events-builder',
  templateUrl: './events-builder.component.html',
  styleUrls: ['./events-builder.component.scss']
})
export class EventsBuilderComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

  openWinterHikeSignUp() {
    window.open("../assets/docs/winterwanderung.doc", "_blank");
  }

}
