export const metaData = {
    home: {
        meta: {
            title: "Herzlich Willkommen auf der Webseite des Turnvereins 1903 Merxheim e.V.",
            description: "Bei uns im TV Merxheim haben wir eine große Anzahl an sportlichen Aktivitäten. Schau doch mal rein und lass dich inspirieren."
        }
    },
    member: {
        meta: {
            title: "Mitglied werden",
            description: "Werde auch Du Mitglied in unserem vielseitigen Verein, entdecke die unterschiedlichen Aktivitäten und gestalte das Vereinsleben mit."
        }
    },
    activities: {
        meta: {
            title: "Aktivitäten",
            description: "In unserem Turnverein gibt es immer viel zu tun und zu entdecken. Wir bieten eine breite Palette an Aktivitäten für Menschen jeden Alters und jeder Fitnessstufe. Aber nicht nur sportliche Betätigung steht bei uns im Vordergrund, sondern auch der Spaß und das Miteinander. Schau doch einfach mal vorbei und lerne uns kennen!"
        }
    },
    commitee: {
        meta: {
            title: "Unser Vorstand",
            description: "Vorstandsmitglieder des Turnvereins 1903 Merxheim e.V."
        }
    },
    contact: {
        meta: {
            title: "Dein Kontakt zum Turnverein 1903 Merxheim e.V.",
            description: "Du hast Fragen zu unseren Aktivitäten oder benötigst Hilfe rund um das Thema Mitgliedschaft? Dann schreib uns gerne eine Nachricht und wir setzen uns mit Dir in Verbindung. Deine Daten werden nicht an Dritte weitergegeben."
        }
    },
    events: {
        meta: {
            title: "Termine",
            description: "Wir freuen uns auch in diesem Jahr wieder auf viele spannende Verantaltungen. Schaue regelmäßig vorbei um nichts zu verpassen. Wir freuen uns darauf, Dich bei einem unserer nächsten Events begrüßen zu dürfen."
        }
    },
    news: {
        meta: {
            title: "Neuigkeiten",
            description: "Auf unserer Webseite gibt es immer wieder spannende Neuigkeiten zu entdecken. Ob neue Aktivitäten, interessante Verantaltungen oder wichtige Ankündigungen - wir halten Dich stets auf dem Laufenden. Besuche uns regelmäßig, um keine Neuigkeiten zu verpassen."
        }
    },
    about: {
        meta: {
            title: "Impressum",
            description: "Angaben gemäß §5 TMG Turnverein 1903 Merxheim e.V. Wilhelmstraße 10 55627 Merxheim; Kontakt Telefon 06754 8553 E-Mail: kontakt@tv-merxheim.de; Haftung für Inhalte Die Inhalte dieser Seiten wurden mit größter Sorgfalt erstellt. Für die Richtigkeit, Vollständigkeit und Aktualität der Inhalte können wir jedoch keine Gewähr übernehmen."
        }
    },
    privacy: {
        meta: {
            title: "Datenschutz",
            description: "Datenschutzerklärung Einleitung Mit der folgenden Datenschutzerklärung möchten wir Sie darüber aufklären, welche Arten Ihrer personenbezogenen Daten (nachfolgend auch kurz als Daten bezeichnet) wir zu welchen Zwecken und in welchem Umfang verarbeiten. Die Datenschutzerklärung gilt für alle von uns durchgeführten Verarbeitungen personenbezogener Daten, sowohl im Rahmen der Erbringung unserer Leistungen als auch insbesondere auf unseren Webseiten, in mobilen Applikationen sowie innerhalb externer Onlinepräsenzen, wie z.B. unserer Social-Media-Profile (nachfolgend zusammenfassend bezeichnet als Onlineangebot)."
        }
    } 
}
