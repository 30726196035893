<div class="news-content">
    <mat-card class="news-card" style="font-weight: 300; font-family: 'Akshar', sans-serif; padding: 0px;">
        <img src="../../assets/img/tv_merxheim_logo.png" style="width: 50%; height: 150px;">
        <div style="padding: 16px;">
            <div style="float: right;">14.12.2023</div>
            <mat-card-title style="color:#f44336; line-height: 1.5rem;">Anmeldung Winterwanderung</mat-card-title>
            <mat-card-subtitle style="font-size: 1.0rem; font-weight: 500;">Wanderung von Merxheim nach Kirschroth</mat-card-subtitle>
            <mat-card-content>
                <p style="font-size: 1.0rem; color: #766e66;">
                    Bald ist es endlich soweit! Am 14. Januar 2024 führt uns unser Weg von Merxheim nach Kirschroth zum Weingut Theis in die "Kleine Auszeit". <br>
                    Ideal, um den über die Festtage angesammelten Kilos den Kampf anzusagen. Los geht's um 10:00 Uhr am alten Rathaus. <br><br>
                    Das Formular zur Anmeldung findet ihr <a href="../../assets/docs/Anmeldung_Winterwanderung.pdf" target="_blank">hier</a>. <br><br>
                    Wir freuen uns auf euch,<br>
                    euer<span style="color:#f44336; font-family: Allura, cursive; font-size: 1.4rem;"> Turnverein Merxheim</span>
                    <br><br>
                </p>
                <!--p style="font-size: 1.0rem; color: #766e66;"></a>.</p-->
            </mat-card-content>
            <mat-divider inset></mat-divider>
        </div>
    </mat-card>   
    <mat-card class="news-card" style="font-weight: 300; font-family: 'Akshar', sans-serif; padding: 0px;">
        <img src="../../assets/img/tv_merxheim_logo.png" style="width: 50%; height: 150px;">
        <div style="padding: 16px;">
            <div style="float: right;">15.10.2023</div>
            <mat-card-title style="color:#f44336; line-height: 1.5rem;">Event abgesagt</mat-card-title>
            <mat-card-subtitle style="font-size: 1.0rem; font-weight: 500;">Weihnachtsmarktfahrt findet nicht statt</mat-card-subtitle>
            <mat-card-content>
                <p style="font-size: 1.0rem; color: #766e66;">
                    Wir müssen euch heute leider mitteilen, dass die Weihnachtsmarktfahrt nach Luxemburg abgesagt wurde. <br>
                    Wermutstropfen bleibt die Winterwanderung, am 14. Januar, die wir weiterhin fest eingeplant haben. <br><br>
                    Wir freuen uns auf euch,<br>
                    euer<span style="color:#f44336; font-family: Allura, cursive; font-size: 1.4rem;"> Turnverein Merxheim</span>
                    <br><br>
                </p>
                <!--p style="font-size: 1.0rem; color: #766e66;"></a>.</p-->
            </mat-card-content>
            <mat-divider inset></mat-divider>
        </div>
    </mat-card>   
    <mat-card class="news-card" style="font-weight: 300; font-family: 'Akshar', sans-serif; padding: 0px;">
        <img src="../../assets/img/tv_merxheim_logo.png" style="width: 50%; height: 150px;">
        <div style="padding: 16px;">
            <div style="float: right;">15.07.2023</div>
            <mat-card-title style="color:#f44336; line-height: 1.5rem;">Ankündigung zweier Events</mat-card-title>
            <mat-card-subtitle style="font-size: 1.0rem; font-weight: 500;">Weihnachtsmarktfahrt und Winterwanderung</mat-card-subtitle>
            <mat-card-content>
                <p style="font-size: 1.0rem; color: #766e66;">
                    Wir freuen uns euch heute schon mitteilen zu können, dass es auch dieses und Anfang nächstes Jahr wieder eine Weihnachtsmarktfahrt 
                    und eine Winterwanderung geben wird. <br><br>
                    Ziel wird dieses Mal der Weihnachtsmarkt in der schönen Stadt Luxemburg sein. Die Fahrt findet am Samstag, den <span style="font-weight: 500;">16.12.2023</span> statt.<br><br>
                    Das Ziel der Wanderung ist aktuell noch nicht bekannt, stattfinden wird diese aber am <span style="font-weight: 500;">14.01.2024</span>. <br><br>
                    Ein Formular zur Anmeldung werden wir in Kürze bereitstellen. <br><br>
                    Haltet euch gerne beide Termine frei! <br><br>
                    Wir freuen uns auf euch,<br>
                    euer<span style="color:#f44336; font-family: Allura, cursive; font-size: 1.4rem;"> Turnverein Merxheim</span>
                    <br><br>
                </p>
                <!--p style="font-size: 1.0rem; color: #766e66;"></a>.</p-->
            </mat-card-content>
            <mat-divider inset></mat-divider>
        </div>
    </mat-card>   
</div>
