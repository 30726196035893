<div class="activity-content">
    <mat-card class="activity-card" *ngFor="let activity of activities">
        <img mat-card-image src="{{ activity.image }}">
        <mat-card-content>
          <p class="activity-name">{{ activity.name }}</p>
          <p class="activity-description">{{ activity.description }}</p>
        </mat-card-content>
        <mat-card-actions>
          <div (click)="openDialog(activity)">Mehr zu {{ activity.name }} erfahren</div><mat-icon>chevron_right</mat-icon>
        </mat-card-actions>
      </mat-card>
</div>

