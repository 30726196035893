<div class="events-content">
    <!--mat-card class="event-card">
      <img mat-card-image src="../../assets/img/tv_merxheim_logo.png" alt="Logo des Turnvereins Merxheim">
        <mat-card>
          <mat-card-title>Sa., 15.04.23</mat-card-title>
          <mat-card-subtitle>18:00 Uhr</mat-card-subtitle>
        </mat-card>
        <mat-card-title>Jahreshauptversammlung</mat-card-title>
        <mat-card-subtitle>Einladung zur Jahreshauptversammlung 2023</mat-card-subtitle>
        <mat-card-content>
          <p>
            Hiermit laden wir alle Mitglieder des Turnvereins 1903 Merxheim e.V. recht herzlich zur diesjährigen Jahreshauptversammlung ein.<br><br>
            Die Tagesordnungspunkte sowie weitere Informationen erhaltet ihr <a href="../../assets/docs/Einladung JHV 2023 Mitglieder.pdf" target="_blank">hier</a>.<br>
            Die Tischvorlage könnt ihr <a href="../../assets/docs/Tischvorlage 2023 JHV.pdf" target="_blank">hier</a> einsehen.
            <br><br>
            Wir freuen uns auf euer Kommen.
          </p>
        </mat-card-content>
        <mat-card-footer>
          <mat-divider inset></mat-divider>
          <p><mat-icon>location_on</mat-icon>Mehrzweckhalle Merxheim</p>
        </mat-card-footer>
    </mat-card-->
    <p class="no-events">Aktuell gibt es keine anstehenden Termine.</p>
  </div>
