<div class="content">
    <section>
        <h2>MITGLIED WERDEN</h2>
        <p>Werde auch Du Mitglied in unserem vielseitigen Verein, entdecke die unterschiedlichen Aktivitäten und gestalte das Vereinsleben mit.</p>
        <section style="display: flex; flex-direction: row; background: #1d2128; height: 30rem; align-items: center; width: 100vw; margin-top:40px;">
            <div class="container">
                <p class="header">
                    Jetzt Mitglied werden
                </p>
                <p class="sub">
                    Hier findest Du die benötigten Dokumente, um Mitglied in unserem Verein zu werden. <br>
                    Solltest Du noch Fragen haben, kannst Du gerne <a href="/contact">Kontakt</a> mit uns aufnehmen.
                </p>
                <div class="documents">
                    <p><mat-icon>description</mat-icon><a href="../../assets/docs/Satzung_Turnverein.pdf" target="_blank">Satzung - PDF Dokument</a></p>
                    <p><mat-icon>description</mat-icon><a href="../../assets/docs/Aufnahmeantrag.doc">Aufnahmeantrag - Word Dokument</a></p>
                    <p><mat-icon>description</mat-icon><a href="../../assets/docs/Ummeldeantrag.doc">Ummeldeantrag - Word Dokument</a></p>
                </div>
            </div>
            <div style="display: flex; justify-content: center;" fxShow fxHide.lt-md>
                <img src="../../assets/img/document.jpg" style="width:50%">
            </div>
        </section>
    </section>
</div>