import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';

// Import Components
import { AppComponent } from './app.component';
import { HomeComponent } from './home/home.component';
import { AboutComponent } from './about/about.component';
import { PrivacyComponent } from './privacy/privacy.component';

// Angular Material Components
import {MatToolbarModule} from '@angular/material/toolbar';
import {MatInputModule } from '@angular/material/input';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatIconModule} from '@angular/material/icon';
import {MatButtonModule} from '@angular/material/button';
import {MatCardModule} from '@angular/material/card';
import {MatDividerModule} from '@angular/material/divider';
import {MatMenuModule} from '@angular/material/menu';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatListModule} from '@angular/material/list';
import {MatDialogModule} from '@angular/material/dialog';
import {MatSidenavModule} from '@angular/material/sidenav';
import { NewsComponent } from './news/news.component';
import { NewsBuilderComponent } from './news-builder/news-builder.component';
import { HallPlanComponent } from './hall-plan/hall-plan.component';
import { ActivitiesComponent } from './activities/activities.component';
import { ContactComponent } from './contact/contact.component';
import { ActivityBuilderComponent } from './activity-builder/activity-builder.component';
import { EventsComponent } from './events/events.component';
import { EventsBuilderComponent } from './events-builder/events-builder.component';
import { MemberComponent } from './member/member.component';
import { ContactService } from './contact/contact.service';
import { CommitteeComponent } from './committee/committee.component';
import { ActivityDetailsComponent } from './activity-details/activity-details.component';


@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    AboutComponent,
    PrivacyComponent,
    NewsComponent,
    NewsBuilderComponent,
    HallPlanComponent,
    ActivitiesComponent,
    ContactComponent,
    ActivityBuilderComponent,
    EventsComponent,
    EventsBuilderComponent,
    MemberComponent,
    CommitteeComponent,
    ActivityDetailsComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    MatToolbarModule,
    MatIconModule,
    MatButtonModule,
    MatCardModule,
    MatDividerModule,
    MatMenuModule,
    FlexLayoutModule,
    MatInputModule,
    MatFormFieldModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    MatCheckboxModule,
    MatListModule,
    MatDialogModule,
    MatSidenavModule
  ],
  providers: [ContactService],
  bootstrap: [AppComponent]
})
export class AppModule { }
