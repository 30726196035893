import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AboutComponent } from './about/about.component';
import { ActivitiesComponent } from './activities/activities.component';
import { CommitteeComponent } from './committee/committee.component';
import { ContactComponent } from './contact/contact.component';
import { EventsComponent } from './events/events.component';
import { HomeComponent } from './home/home.component';
import { MemberComponent } from './member/member.component';
import { NewsComponent } from './news/news.component';
import { PrivacyComponent } from './privacy/privacy.component';

import { metaData } from './metadata';

const routes: Routes = [
  { path: '', component: HomeComponent, data: metaData.home },
  { path: 'news', component: NewsComponent, data: metaData.news },
  { path: 'events', component: EventsComponent, data: metaData.events },
  { path: 'activities', component: ActivitiesComponent, data: metaData.activities },
  { path: 'contact', component: ContactComponent, data: metaData.contact },
  { path: 'about', component: AboutComponent, data: metaData.about },
  { path: 'privacy', component: PrivacyComponent, data: metaData.privacy },
  { path: 'member', component: MemberComponent, data: metaData.member },
  { path: 'committee', component: CommitteeComponent, data: metaData.commitee },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { scrollPositionRestoration: 'enabled' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
