export interface Activity {
    image: string;
    name: string;
    times: Time[];
    admin: string;
    participants: string;
    description: string;
}

interface Time {
    day: string;
    time: string;
    age?:string;
}

export const activities:Activity[] = [
    {
        image: '../../assets/img/linedance.jpeg',
        name: 'Linedance',
        times: [
            {
                day: 'Do.',
                time: '19:00 - 20:00'
            }
        ],
        admin: 'K. Henrich-Bautz',
        participants: '10+',
        description: 'Kombinierte Schrittfolgen, die sich wiederholt bis zu vier Bewegungsrichtungen getanzt/ausgeführt werden'
    },
    {
        image: '../../assets/img/tischtennis.jpeg',
        name: 'Tischtennis',
        times: [
            {
                day: 'Di.',
                time: '17:15 - 18:45'
            },
            {
                day: 'Fr.',
                time: '18:30 - 20:30'
            }
        ],
        admin: 'H. Listner',
        participants: '5-10',
        description: ''
    },
    {
        image: '../../assets/img/tabata.jpeg',
        name: 'Tabata',
        times: [
            {
                day: 'Mo.',
                time: '19:00 - 20:00'
            },
            {
                day: 'Fr.',
                time: '17:30 - 18:30'
            },
        ],
        admin: 'K. Henrich-Bautz / A. Hubert',
        participants: '10-20',
        description: 'HIIT (Hoch-intensives-Intervall-Training) Programm, Zirkeltraining - auch für Anfänger geeignet.'
    },
    {
        image: '../../assets/img/zumba.jpeg',
        name: 'Zumba',
        times: [{
            day: 'Fr.',
            time: '20:00 - 21:00'
        }],
        admin: 'M. Dietz',
        participants: '5-10',
        description: ''
    },
    {
        image: '../../assets/img/gymnastik.jpeg',
        name: 'Montags-Frauen Gymnastik',
         times: [{
            day: 'Mo.',
            time: '20:00 - 21:00'
        }],
        admin: 'I. Zerfass',
        participants: '5-10',
        description: ''
    },
    {
        image: '../../assets/img/no_image.jpeg',
        name: 'Pluspunkt Gesundheit',
         times: [{
            day: 'Di.',
            time: '09:30 - 10:30'
        }],
        admin: 'K. Franzen',
        participants: '5-10',
        description: ''
    },
    {
        image: '../../assets/img/walking.jpeg',
        name: 'Walking',
         times: [{
            day: 'Di.',
            time: '13:30 - 14:30'
        }],
        admin: 'A. Nürnberg',
        participants: '10',
        description: ''
    },
    {
        image: '../../assets/img/senioren_spielenachmittag.JPG',
        name: 'Senioren Spielenachmittag',
         times: [{
            day: 'Di.',
            time: '15:00 - 17:00'
        }],
        admin: 'A. Nürnberg',
        participants: '5-10',
        description: 'Ort: Ev. Gemeindehaus'
    },
    {
        image: '../../assets/img/no_image.jpeg',
        name: 'MuKi',
         times: [{
            day: 'Di.',
            time: '15:00 - 17:00'
        }],
        admin: 'L. Carmin',
        participants: '5-10',
        description: 'Gemeinsam mit den Eltern Motorik, Koordination und den Spaß am Sport fördern.'
    },
    {
        image: '../../assets/img/no_image.jpeg',
        name: 'Gesundheitskurse',
         times: [{
            day: 'Di.',
            time: '19:00 - 20:00'
        }],
        admin: 'K. Franzen',
        participants: '5-10',
        description: ''
    },
    {
        image: '../../assets/img/kinderturnen_example.jpeg',
        name: 'Vorschule',
         times: [{
            day: 'Mi.',
            time: '16:00 - 17:00'
        }],
        admin: 'I. Zerfass',
        participants: '5-10',
        description: 'Spielerisches sammeln von Bewegungserfahrungen sowie das Erlernen der Grundfertigkeiten, wie zum Beispiel Balancieren, Hüpfen, Schwingen und Rollen.'
    },
    {
        image: '../../assets/img/geraeteturnen.jpeg',
        name: 'Geräteturnen',
         times: [{
            day: 'Mi.',
            time: '17:00 - 18:30'
        }],
        admin: 'I. Zerfass',
        participants: '5-10',
        description: ''
    },
    {
        image: '../../assets/img/bauchbeinepo.jpeg',
        name: 'Bauch Beine Po',
         times: [{
            day: 'Mi.',
            time: '18:30 - 19:30'
        }],
        admin: 'I. Zerfass',
        participants: '5-10',
        description: ''
    },
    {
        image: '../../assets/img/no_image.jpeg',
        name: 'Mittwochs-Frauen',
        times: [{
            day: 'Mi.',
            time: '19:30 - 20:30'
        }],
        admin: 'A. Nürnberg',
        participants: '20+',
        description: ''
    },
    {
        image: '../../assets/img/kraftausdauertraining.jpeg',
        name: 'Kraft-/Ausdauertraining',
        times: [
            {
                day: 'Fr.',
                time: '14:45 - 16:00',
                age: '1.-5. Klasse'
            },
            {
                day: 'Fr.',
                time: '16:00 - 17:30',
                age: '5.-13. Klasse'
            }
        ],
        admin: 'K. Henrich-Bautz',
        participants: '10-20',
        description: 'Kraft, Ausdauer, Koordination, Spiel und Spaß'
    },
];