import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { SeoService } from '../seo.service';
import { ContactService } from './contact.service';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss'],
  providers: [ContactService]
})
export class ContactComponent implements OnInit {

  formGroup!:FormGroup

  constructor(
    private contact:ContactService,
    private seoService:SeoService,
    private route:ActivatedRoute
  ) { }

  ngOnInit(): void {
    this.formGroup = new FormGroup({
      name: new FormControl('', [Validators.required]),
      email: new FormControl('', [Validators.required, Validators.email]),
      message: new FormControl('', [Validators.required]),
      acceptPrivacy: new FormControl(false, [Validators.requiredTrue]),
    })

    const { meta } = this.route.snapshot.data;
    this.seoService.updateTitle(meta.title);
    this.seoService.updateDescription(meta.description);
  }

  onSubmit() {
    const formObject:any = this.formGroup.getRawValue(); 
    const jsonFormObject:string = JSON.stringify(formObject);
    console.log(jsonFormObject);

    this.contact.postMessage(jsonFormObject).subscribe({
      error: (e) => console.error(e),
      complete: () => {
        location.href = 'https://mailthis.to/confirm';
        this.formGroup.reset();
      } 
    });
  }
}
