<div class="content">
    <img src="{{data.image}}">
    <p class="activity-name">{{data.name}}</p>
    <div class="activities">
        <p class="activity-time"><mat-icon>groups</mat-icon>{{ data.participants }} Personen</p>
        <div class="activity-time"><mat-icon>schedule</mat-icon>
            <div *ngFor="let time of data.times">
                {{time.day}}, {{time.time}} <br>
                {{time.age}}
            </div>
        </div>
        <p class="activity-time"><mat-icon>person</mat-icon>{{ data.admin }}</p>
    </div>
</div>
