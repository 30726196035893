<div class="hall-plan">
    <section class="hall-plan-days">
        <p>Montag</p>
        <p>Dienstag</p>
        <p>Mittwoch</p>
        <p>Donnerstag</p>
        <p>Freitag</p>
    </section>
    <section class="hall-plan-section">
        <p>vormittags</p>
        <div>
            <div>
            </div>
            <div>
                <article class="activity-card">
                    <div class="activity-name">
                        Pluspunkt Gesundheit
                    </div>
                    <div class="activity-time">
                        9:30 - 10:30
                    </div>
                </article>
            </div>
            <div>
                <article class="activity-card">
                    <div class="activity-name">
                        Krabbelgruppe
                    </div>
                    <div class="activity-time">
                        10:00 - 11:30
                    </div>
                </article>
            </div>
            <div>
            </div>
            <div>
            </div>
        </div>
    </section>
    <section class="hall-plan-section">
        <p>nachmittags</p>
        <div>
            <div>
            </div>
            <div>
                <article class="activity-card">
                    <div class="activity-name">
                        MuKi
                    </div>
                    <div class="activity-time">
                        15:00 - 17:00
                    </div>
                </article>
            </div>
            <div>
                <article class="activity-card">
                    <div class="activity-name">
                        Vorschule
                    </div>
                    <div class="activity-time">
                        16:00 - 17:00
                    </div>
                </article>
            </div>
            <div>
            </div>
            <div>
                <article class="activity-card">
                    <div class="activity-name">
                        Kraft und Ausdauer-Training
                    </div>
                    <div class="activity-time">
                        14:45 - 17:30
                    </div>
                </article>
            </div>
        </div>
    </section>
    <section class="hall-plan-section">
        <p>abends</p>
        <div>
            <div>
                <article class="activity-card">
                    <div class="activity-name">
                        Tabata
                    </div>
                    <div class="activity-time">
                        19:00 - 20:00
                    </div>
                </article>
                <article class="activity-card">
                    <div class="activity-name">
                        Montags-Frauen Gymn.
                    </div>
                    <div class="activity-time">
                        20:00 - 21:00
                    </div>
                </article>
                <article class="activity-card">
                    <div class="activity-name">
                        Tanz
                    </div>
                    <div class="activity-time">
                        20:00 - 22:00
                    </div>
                </article>
            </div>
            <div>
                <article class="activity-card">
                    <div class="activity-name">
                        Tischtennis
                    </div>
                    <div class="activity-time">
                        17:15 - 18:45
                    </div>
                </article>
                <article class="activity-card">
                    <div class="activity-name">
                        Walking
                    </div>
                    <div class="activity-time">
                        18:00 - 19:00
                    </div>
                </article>
                <article class="activity-card">
                    <div class="activity-name">
                        Gesundheitskurse
                    </div>
                    <div class="activity-time">
                        19:00 - 20:00
                    </div>
                </article>
            </div>
            <div>
                <article class="activity-card">
                    <div class="activity-name">
                        Geräteturnen
                    </div>
                    <div class="activity-time">
                        17:00 - 18:30
                    </div>
                </article>
                <article class="activity-card">
                    <div class="activity-name">
                        Bauch Beine Po
                    </div>
                    <div class="activity-time">
                        18:30 - 19:30
                    </div>
                </article>
                <article class="activity-card">
                    <div class="activity-name">
                        Mittwochs-Frauen
                    </div>
                    <div class="activity-time">
                        19:30 - 20:30
                    </div>
                </article>
            </div>
            <div>
                <article class="activity-card">
                    <div class="activity-name">
                        Linedance
                    </div>
                    <div class="activity-time">
                        19:00 - 20:00
                    </div>
                </article>
            </div>
            <div>
                <article class="activity-card">
                    <div class="activity-name">
                        Tabata
                    </div>
                    <div class="activity-time">
                        17:30 - 18:30
                    </div>
                </article>
                <article class="activity-card">
                    <div class="activity-name">
                        Tischtennis
                    </div>
                    <div class="activity-time">
                        18:30 - 20:30
                    </div>
                </article>
                <article class="activity-card">
                    <div class="activity-name">
                        Zumba
                    </div>
                    <div class="activity-time">
                        20:00 - 21:00
                    </div>
                </article>
            </div>
        </div>
    </section>
</div>