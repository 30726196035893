<header class="header">
    <div class="header-title-section fade-in">
        <p class="title">Sport ist unsere Leidenschaft</p>
        <div class="button-row">
            <button mat-flat-button style="margin-right: 15px;" [routerLink]="['/member']">JETZT MITGLIED WERDEN</button>
            <button mat-stroked-button [routerLink]="['/activities']">AKTIVITÄTEN ENTDECKEN</button>
        </div>
    </div>
    <div class="overlay"></div>
    <img class="header-image" src="../../assets/img/entry-image.jpg">
</header>
<div class="content">
    <section class="news-section">
      <h2>NEUIGKEITEN</h2>
      <app-news-builder></app-news-builder>
      <!--h2>TERMINE</h2>
      <app-events-builder></app-events-builder-->
    </section>
    <section class="activity-section">
        <div>
            <p style="color: #ffffff;font-family: 'Akshar', sans-serif; font-size: 3.0rem; font-weight: 500;text-align: start; padding: 0 0 0 30px; line-height: 1.2em;">
                Finde jetzt<br>
                die Aktivität,<br>
                die zu Dir passt<br>
            </p>
            <p style="text-align:justify; color: #ffffff;font-family: 'Akshar', sans-serif; font-size: 1.2rem; line-height: 1.2em; font-weight: 300; padding:10px 0 0 30px; line-height: 1.2em;">
                Bei uns im TV Merxheim haben wir eine <br>
                große Anzahl an sportlichen Aktivitäten. <br>
                Schau doch mal rein und lass dich inspirieren:
            </p>
            <button [routerLink]="['/activities']" mat-stroked-button style="color: #fff; margin: 30px;">JETZT AKTIVITÄTEN ENTDECKEN</button>
        </div>
        <div style="display: flex; justify-content: center;" fxShow fxHide.lt-md>
            <img src="../../assets/img/activity_example.jpeg" style="width: 100%;">
        </div>
    </section>
    <section class="hallplan-section">
        <h2 style="width: 100%; text-align:end;">HALLENBELEGUNG</h2>
        <div style="width: 100%;">
            <div>
                <p style="text-align: end;">
                    Für die Ausübung unserer Aktivitäten steht uns die Mehrzweckhalle in Merxheim zur Verfügung. Hier siehst Du wie diese im Laufe der Woche belegt ist.
                </p>
            </div>
        </div>
        <app-hall-plan></app-hall-plan>
    </section>
</div>